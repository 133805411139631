<template>
  <div class="content">
    <div class="common">
      <div class="download-portal">
        <ul class="portal-sort">
          <!-- window版本 -->
          <li class="download-item">
            <div class="icon-img">
              <img class="" src="../../assets/images/ai-img-w.png" alt="" />
            </div>
            <div class="download-text">
              <h3>{{ $t("window.title") }}</h3>
              <p class="bit-link">
                <a :href="newWindow.url" class="download-btn" @click="postStatEvent(newWindow.version)">
                  <img src="../../assets/images/a-img-download1.png" width="20"
                    style="vertical-align: middle; margin-right: 5px" />
                  {{ $t("window.linkOne") }}
                </a>
                <!-- <a :href="newWindowBeta.url" class="download-btn-small">
									<img src="../../assets/images/a-img-download1.png" width="20" style="vertical-align: middle;margin-right: 5px"/> 
									{{$t('window.linkTwosheenhan')}}
								</a> -->
              </p>
            </div>
            <div class="version-item">
              <p class="version-msg">
                {{ $t("window.version") }}: {{ newWindow.version }}
              </p>
              <p class="version-msg">
                {{ $t("window.updateTime") }}:
                {{ newWindow.createTime | formatDate }}
              </p>
              <p class="version-msg">
                {{ $t("window.compatible") }}: Windows7/8/10/11
              </p>
            </div>
          </li>
          <!-- MAC版本 -->
          <li class="download-item">
            <div class="icon-img">
              <img class="" src="../../assets/images/ai-img-apple.png" alt="" />
            </div>
            <div class="download-text">
              <h3>{{ $t("mac.title") }}</h3>
              <p class="bit-link">
                <a :href="newMac.url" v-if="
                  ['www', 'shadow', 'imouse', 'eweadn', 'dario'].join('') ===
                  webHost
                " class="download-btn-small m-right10" @click="postStatEvent(newMac.version)">
                  <img src="../../assets/images/a-img-download1.png" width="20"
                    style="vertical-align: middle; margin-right: 5px" />
                  {{ $t("mac.linkOne") }}
                </a>
                <a class="download-btn" v-else :href="newMac.url" @click="postStatEvent(newMac.version)">
                  <img src="../../assets/images/a-img-download1.png" width="20"
                    style="vertical-align: middle; margin-right: 5px" />
                  {{ $t("mac.linkOne") }}
                </a>
                <a href="https://apps.apple.com/cn/app/ai-office/id1480139966?mt=12"
                  v-if="['www', 'imouse'].join('') === webHost" class="download-btn-small" target="_blank"
                  @click="postStatEvent('')">
                  {{ $t("mac.linkTwo") }}
                </a>
                <a href="https://apps.apple.com/cn/app/卓尧ai/id6461728650?mt=12" v-if="curHost == 'shadow'"
                  class="download-btn-small" target="_blank" @click="postStatEvent('')">
                  {{ $t("mac.linkTwo") }}
                </a>
              </p>
            </div>
            <div class="version-item">
              <p class="version-msg">
                {{ $t("mac.version") }}: {{ newMac.version }}
              </p>
              <!-- <p class="version-msg">{{$t('mac.size')}}: 18.6M</p> -->
              <p class="version-msg">
                {{ $t("mac.updateTime") }}: {{ newMac.createTime | formatDate }}
              </p>
              <p class="version-msg">
                {{ $t("mac.compatible") }}: Mac 10.15以上
              </p>
            </div>
          </li>
          <!-- 云电脑版本 -->
          <li class="download-item" v-if="
            [
              'yundesk',
              'wo',
              'p70',
              'pansheng',
              'newland',
              'm7',
              'i70',
              'm70',
              'zuoh',
              'sheenhan',
              'sheenhan.gpt-mouse.com',
              'yff',
              'tele',
              'hbs',
              'www.hbs',
            ].includes(webHost)
          ">
            <div class="icon-img">
              <img class="" src="../../assets/images/ai-img-yundesk.png" alt="" />
            </div>
            <div class="download-text">
              <h3>{{ $t("windowYun.title") }}</h3>
              <p class="bit-link">
                <a class="download-btn" :href="getDownloadYunUrl().url"
                  @click="postStatEvent(getDownloadYunUrl().version)">
                  <img src="../../assets/images/a-img-download1.png" width="20"
                    style="vertical-align: middle; margin-right: 5px" />
                  {{ $t("windowYun.linkOne") }}
                </a>
              </p>
            </div>
            <div class="version-item">
              <p class="version-msg">
                {{ $t("windowYun.version") }}: {{ getDownloadYunUrl().version }}
              </p>
              <!-- <p class="version-msg">{{$t('mac.size')}}: 18.6M</p> -->
              <p class="version-msg">
                {{ $t("windowYun.updateTime") }}:
                {{ getDownloadYunUrl().createTime }}
              </p>
              <p class="version-msg">
                {{ $t("windowYun.compatible") }}: windows MobileYun
              </p>
            </div>
          </li>
          <!-- 统信版本 -->
          <li class="download-item" v-if="
            [
              'yundesk',
              'wo',
              'pansheng',
              'newland',
              'm70',
              'p70',
              'i70',
              'm7',
              'zuoh',
              'sheenhan',
              'sheenhan.gpt-mouse.com',
              'yff',
              'tele',
              'hbs',
              'www.hbs',
            ].includes(webHost)
          ">
            <div class="icon-img">
              <img class="" src="../../assets/images/ai-img-tx.png" alt="" />
            </div>
            <div class="download-text">
              <h3>{{ $t("txYun.title") }}</h3>
              <p class="bit-link">
                <a class="download-btn" :href="getDownloadTXUrl().url"
                  @click="postStatEvent(getDownloadTXUrl().version)">
                  <img src="../../assets/images/a-img-download1.png" width="20"
                    style="vertical-align: middle; margin-right: 5px" />
                  {{ $t("txYun.linkOne") }}
                </a>
              </p>
            </div>
            <div class="version-item">
              <p class="version-msg">
                {{ $t("txYun.version") }}: {{ getDownloadTXUrl().version }}
              </p>
              <!-- <p class="version-msg">{{$t('mac.size')}}: 18.6M</p> -->
              <p class="version-msg">
                {{ $t("txYun.updateTime") }}:
                {{ getDownloadTXUrl().createTime }}
              </p>
              <p class="version-msg">
                {{ $t("txYun.compatible") }}: UOS Desktop(X86)
              </p>
            </div>
          </li>
          <!-- 麒麟版本 -->
          <li class="download-item" v-if="
            [
              'yundesk',
              'wo',
              'pansheng',
              'newland',
              'm70',
              'p70',
              'i70',
              'm7',
              'zuoh',
              'yff',
              'tele',
              'hbs',
              'www.hbs',
              'sheenhan',
              'sheenhan.gpt-mouse.com',
            ].includes(webHost)
          ">
            <div class="icon-img">
              <img class="" src="../../assets/images/ai-img-ql.png" alt="" />
            </div>
            <div class="download-text">
              <h3>{{ $t("qlYun.title") }}</h3>
              <p class="bit-link">
                <a class="download-btn" :href="getDownloadQLUrl().url"
                  @click="postStatEvent(getDownloadQLUrl().version)">
                  <img src="../../assets/images/a-img-download1.png" width="20"
                    style="vertical-align: middle; margin-right: 5px" />
                  {{ $t("qlYun.linkOne") }}
                </a>
              </p>
            </div>
            <div class="version-item">
              <p class="version-msg">
                {{ $t("qlYun.version") }}: {{ getDownloadQLUrl().version }}
              </p>
              <!-- <p class="version-msg">{{$t('mac.size')}}: 18.6M</p> -->
              <p class="version-msg">
                {{ $t("qlYun.updateTime") }}:
                {{ getDownloadQLUrl().createTime }}
              </p>
              <p class="version-msg">
                {{ $t("qlYun.compatible") }}: V10 and above
              </p>
            </div>
          </li>
          <!-- 麒麟arm版本 -->
          <li class="download-item" v-if="['yff'].includes(webHost)">
            <div class="icon-img">
              <img class="" src="../../assets/images/ai-img-ql.png" alt="" />
            </div>
            <div class="download-text">
              <h3>{{ $t("qlYun.armTitle") }}</h3>
              <p class="bit-link">
                <a class="download-btn" :href="getDownloadQLArmUrl().url"
                  @click="postStatEvent(getDownloadQLArmUrl().version)">
                  <img src="../../assets/images/a-img-download1.png" width="20"
                    style="vertical-align: middle; margin-right: 5px" />
                  {{ $t("qlYun.linkOne") }}
                </a>
              </p>
            </div>
            <div class="version-item">
              <p class="version-msg">
                {{ $t("qlYun.version") }}: {{ getDownloadQLArmUrl().version }}
              </p>
              <!-- <p class="version-msg">{{$t('mac.size')}}: 18.6M</p> -->
              <p class="version-msg">
                {{ $t("qlYun.updateTime") }}:
                {{ getDownloadQLArmUrl().createTime }}
              </p>
              <p class="version-msg">{{ $t("qlYun.compatible") }}: 麒麟arm</p>
            </div>
          </li>

          <li class="download-item" v-if="['www'].join('') === webHost">
            <div class="icon-img img-over img-out" v-show="androidIcon" @mouseover="overAndroidCode">
              <img class="" src="../../assets/images/ai-img-android.png" alt="" />
            </div>
            <div class="android-code img-over img-out" v-if="androidCode" @mouseout="outAndroidCode">
              <!-- <img class="" src="../../assets/images/ai-img-acode.png" alt="" /> -->
              <vue-qr :logoSrc="logoIcon" :text="newAndroid.url" :size="110"></vue-qr>
            </div>
            <div class="download-text">
              <h3>{{ $t("android.title") }}</h3>
              <p class="bit-link">
                <a class="download-android m-right10" :href="newAndroid.url" target="_blank"
                  @click="postStatEvent(newAndroid.version)">
                  <img src="../../assets/images/a-img-download1.png" width="20"
                    style="vertical-align: middle; margin-right: 5px" />
                  {{ $t("android.linkOne") }}
                </a>
                <a class="download-android" target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.qjzn.earphone" @click="postStatEvent('')">{{
                    $t("android.linkTwo") }}</a>
              </p>
            </div>
            <div class="version-item">
              <p class="version-msg">
                {{ $t("android.version") }}: {{ newAndroid.version }}
              </p>
              <!-- <p class="version-msg">{{$t('android.size')}}: 18.6M</p> -->
              <p class="version-msg">
                {{ $t("android.updateTime") }}:
                {{ newAndroid.createTime | formatDate }}
              </p>
              <p class="version-msg">{{ $t("android.compatible") }}: Android</p>
            </div>
          </li>
        </ul>
      </div>
      <div class="history-version" v-if="['www'].join('') === webHost">
        <!-- <router-link to="/HistoryVersion" target="_blank">展开所有历史版本</router-link> -->
        <div>
          <a href="javascript:;" @click="showAllVersions = !showAllVersions">{{
            $t("history.title")
          }}</a>
        </div>
        <!-- 各版本列表 -->
        <div class="version-table" v-show="showAllVersions">
          <!-- <div class="version-no">
						<p>{{$t('history.tip')}}</p>
					</div> -->
          <div class="version-list">
            <table class="version-data">
              <tr>
                <th class="data-title">{{ $t("history.package") }}</th>
                <th class="data-title">{{ $t("history.product") }}</th>
                <th class="data-title">{{ $t("history.historyVersion") }}</th>
                <th class="data-title">{{ $t("history.applicableSystem") }}</th>
                <th class="data-title">{{ $t("history.time") }}</th>
                <th class="data-title">{{ $t("history.download") }}</th>
              </tr>
              <tr v-for="window in windowVersions" :key="window.id">
                <td class="data-content">
                  <!-- <img class="data-icon" src="../../assets/images/ai-img-w.png" alt="" /> -->
                  {{ window.title }}
                  <div class="version-intro">
                    <img class="info-img" src="../../assets/images/info-circle-filled.png"
                      @mouseenter="showVersionDescribe(window.id)" @mouseleave="hideVersionDescribe" />
                    <div class="detailed box-shadow" v-if="describeId == window.id">
                      <h5 class="desc-title">
                        <img src="../../assets/images/info-circle-filled-b.png" />版本说明
                      </h5>
                      <p v-html="window.contents"></p>
                    </div>
                  </div>
                </td>
                <td class="data-content">{{ window.product }}</td>
                <td class="data-content">{{ window.version }}</td>
                <td class="data-content">
                  <img class="data-icon" src="../../assets/images/ai-img-w.png" alt="" />
                </td>
                <td class="data-content">
                  {{ window.createTime | formatDate }}
                </td>
                <td class="data-content">
                  <img class="data-download" src="../../assets/images/ai-img-download.png" width="20" height="20" />
                  <a :href="window.url" class="current-download" @click="postStatEvent(window.version)">{{
                    $t("history.download") }}</a>
                </td>
              </tr>
              <tr v-for="mac in macVersions" :key="mac.id">
                <td class="data-content">
                  {{ mac.title }}
                  <div class="version-intro">
                    <img class="info-img" src="../../assets/images/info-circle-filled.png"
                      @mouseenter="showVersionDescribe(mac.id)" @mouseleave="hideVersionDescribe" />
                    <div class="detailed box-shadow" v-if="describeId == mac.id">
                      <h5 class="desc-title">
                        <img src="../../assets/images/info-circle-filled-b.png" />版本说明
                      </h5>
                      <p>
                        {{ mac.contents }}
                      </p>
                    </div>
                  </div>
                </td>
                <td class="data-content">{{ mac.version }}</td>
                <td class="data-content">
                  <img class="data-icon" src="../../assets/images/ai-img-apple.png" alt="" />
                </td>
                <td class="data-content">{{ mac.createTime | formatDate }}</td>
                <td class="data-content">
                  <img class="data-download" src="../../assets/images/ai-img-download.png" width="20" height="20" />
                  <a :href="mac.url" class="current-download" @click="postStatEvent(mac.version)">{{
                    $t("history.download") }}</a>
                </td>
              </tr>
              <tr v-for="android in androidVersions" :key="android.id">
                <td class="data-content">
                  <!-- <img class="data-icon" src="../../assets/images/ai-img-android.png" alt="" /> -->
                  {{ android.title }}
                  <div class="version-intro">
                    <img class="info-img" src="../../assets/images/info-circle-filled.png"
                      @mouseenter="showVersionDescribe(android.id)" @mouseleave="hideVersionDescribe" />
                    <div class="detailed box-shadow" v-if="describeId == android.id">
                      <h5 class="desc-title">
                        <img src="../../assets/images/info-circle-filled-b.png" />版本说明
                      </h5>
                      <p>
                        {{ android.contents }}
                      </p>
                    </div>
                  </div>
                </td>
                <td class="data-content">{{ android.version }}</td>
                <td class="data-content">
                  <img class="data-icon" src="../../assets/images/ai-img-android.png" alt="" />
                </td>
                <td class="data-content">
                  {{ android.createTime | formatDate }}
                </td>
                <td class="data-content p-relative">
                  <img class="data-download" src="../../assets/images/ai-img-download.png" width="20" height="20" />
                  <a :href="android.url" class="current-download" @click="postStatEvent(android.version)">{{
                    $t("history.download") }}</a>
                  <img class="data-download" src="../../assets/images/ai-img-scan.png" width="20" height="20"
                    @mouseenter="qrShow = true" @mouseleave="qrShow = false" />
                  <vue-qr :logoSrc="logoIcon" class="qr-img box-shadow" v-if="qrShow" :text="android.url"></vue-qr>
                </td>
              </tr>
              <tr v-for="patch in qdVersions" :key="patch.id">
                <td class="data-content">{{ patch.title }}</td>
                <td class="data-content">{{ patch.version }}</td>
                <td class="data-content">
                  <img class="data-icon" src="../../assets/images/ai-img-qd.png" alt="" />
                </td>
                <td class="data-content">
                  {{ patch.createTime | formatDate }}
                </td>
                <td class="data-content">
                  <img class="data-download" src="../../assets/images/ai-img-download.png" width="20" height="20" />
                  <a :href="patch.url" class="current-download" @click="postStatEvent(patch.version)">{{
                    $t("history.download") }}</a>
                </td>
              </tr>
              <tr v-for="hty in historyVersions" :key="hty.id">
                <td class="data-content">
                  <img class="data-icon" v-if="hty.version && hty.version.indexOf('Mouse') != -1"
                    src="../../assets/images/ai-img-mouse1.png" alt="" />
                  <img class="data-icon" v-if="hty.version && hty.version.indexOf('Pointer') != -1"
                    src="../../assets/images/ai-img-pen.png" alt="" />
                  <img class="data-icon" v-if="hty.version && hty.version.indexOf('Recorder') != -1"
                    src="../../assets/images/ai-img-recorder.png" alt="" />
                  <img class="data-icon" v-else-if="
                    hty.version &&
                    hty.version.indexOf('Mouse') == -1 &&
                    hty.version.indexOf('Pointer') == -1 &&
                    hty.version.indexOf('Recorder') == -1
                  " src="../../assets/logo/qingjing/ai-img-logo2.png" alt="" />
                  {{ hty.title }}
                  <div class="version-intro">
                    <img class="info-img" src="../../assets/images/info-circle-filled.png"
                      @mouseenter="showVersionDescribe(hty.id)" @mouseleave="hideVersionDescribe" />
                    <div class="detailed box-shadow" v-if="describeId == hty.id">
                      <h5 class="desc-title">
                        <img src="../../assets/images/info-circle-filled-b.png" />版本说明
                      </h5>
                      <p v-html="hty.contents"></p>
                    </div>
                  </div>
                </td>
                <td class="data-content">
                  <p v-if="hty.products && hty.products[0] == 'Mouse'">鼠标</p>
                  <p v-if="hty.products && hty.products[0] == 'Pointer'">
                    翻页笔
                  </p>
                  <p v-if="hty.products && hty.products[0] == 'Recorder'">
                    录音笔
                  </p>
                </td>
                <td class="data-content">{{ hty.version }}</td>
                <td class="data-content">
                  <p v-if="hty.platform == 'Mac'">
                    <img class="data-icon" src="../../assets/images/ai-img-apple.png" alt="" />
                  </p>
                  <p v-if="hty.platform == 'Android'">
                    <img class="data-icon" src="../../assets/images/ai-img-android.png" alt="" />
                  </p>
                  <p v-if="hty.platform == 'Windows'">
                    <img class="data-icon" src="../../assets/images/ai-img-w.png" alt="" />
                  </p>
                </td>
                <td class="data-content">{{ hty.createTime | formatDate }}</td>
                <td class="data-content p-relative">
                  <img class="data-download" src="../../assets/images/ai-img-download.png" width="20" height="20" />
                  <a :href="hty.url" target="_blank" class="current-download" @click="postStatEvent(hty.version)">{{
                    $t("history.download") }}</a>
                  <img class="data-download" v-if="hty.platform == 'Android'" src="../../assets/images/ai-img-scan.png"
                    width="20" height="20" @mouseenter="qrShow = hty.id" @mouseleave="qrShow = false" />
                  <vue-qr :logoSrc="logoIcon" class="qr-img box-shadow" v-if="qrShow == hty.id"
                    :text="hty.url"></vue-qr>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vueQr from "vue-qr";
import store from "@/store/index.js";
import { sendStatEvent } from "../../util/stat.js";
import { defauleTheme, setMainColor } from "../../util/index.js";

export default {
  data() {
    return {
      curHost: "",
      bitFlag: 0,
      versionFlag: 0,
      showAllVersions: false,
      qrShow: false,
      logoIcon: require("../../assets/logo/qingjing/ai-img-logo2.png"),
      describeId: "",
      //鼠标滑过控制
      androidIcon: true,
      androidCode: false,
      //最新的版本
      newWindow: {},
      newWindowBeta: {},
      newGreen: {},
      newMac: {},
      newAndroid: {},
      newPatch: {},
      //所有版本数组
      windowVersions: [],
      macVersions: [],
      androidVersions: [],
      qdVersions: [],
      historyVersions: [],
      windowBetaVersions: [], //内测版本
      ipAddress: "https://apiv2.qjzn.ltd/api", //  生产：https://apiv2.qjzn.ltd/api， 开发：https://devapi.qjzn.ltd/api
      domainName: "",
      webHost: "",
    };
  },
  components: {
    vueQr,
  },
  mounted() {
    this.curHost = store.getters["getCurrentHost"];
    this.domainName = store.getters["getCurrentDomainName"];
    this.webHost = store.getters["getWebHost"];
    this.setPageColor();
    //获取接口中心数据
    this.fetchVersions();
   
  },
  filters: {
    //配置过滤器
    formatDate: function (value) {
      //调用时间戳为日期显示
      let date = new Date(value);
      let y = date.getFullYear(); //获取年份
      let m = date.getMonth() + 1; //获取月份
      m = m < 10 ? "0" + m : m; //月份不满10天显示前加0
      let d = date.getDate(); //获取日期
      d = d < 10 ? "0" + d : d; //日期不满10天显示前加0
      return y + "-" + m + "-" + d;
      //也可以获取更精准时间
      //let h = date.getHours(),                   //小时
      //let m = date.getMinutes(),                 //分
      //let s = date.getSeconds(),                 //秒
      //let ls = date.getMilliseconds()            //毫秒
    },
  },
  methods: {
    // 设置页面主色调
    setPageColor() {
      let theme = defauleTheme;
      if (this.webHost.includes("xunxiang")) {
        theme = "#00a0ea";
      } else if (this.webHost.includes("hbs")) {
        theme = "#34a8e1";
      } else if (this.webHost.includes("zxhy")) {
        theme = "#03D3C6";
      }
      console.log(theme, 'theme');

      setMainColor(theme);
    },
    //根据不同域名取不同的APPID
    getAppId() {
      let id = "";
      if (
        [
          "www",
          "app",
          "imouse",
          "eweadn",
          "dario",
          "yundesk",
          "xbantu",
          "metav",
          "wo",
          "tele",
          "smartdone",
          "taiguo",
          "m70",
          "p70",
        ].join("") === this.webHost
      ) {
        id = "AiOffice-Destop-H5";
      } else if (this.curHost == "greatwall") {
        id = "AiOffice-XiaoDun-Destop";
      } else if (this.curHost == "aioa") {
        id = "AiOffice-H5-FX-Destop";
      } else if (this.curHost == "jiabo") {
        id = "AiOffice-H5-JiaBo-Destop";
      } else if (this.curHost == "zhunxin") {
        id = "AiOffice-H5-ZhunXin-Destop";
      } else if (this.curHost == "jiyuai") {
        id = "AiOffice-H5-JIYUAI-Destop";
      } else if (this.curHost == "shinco") {
        id = "AiOffice-H5-Shinco-Destop";
      } else if (this.curHost == "lefan") {
        id = "AiOffice-H5-LeFan-Destop";
      } else if (
        ["sheenhan", "sheenhan.gpt-mouse.com"].includes(this.webHost)
      ) {
        id = "AiOffice-H5-Sheenhan-Destop";
      } else if (this.curHost == "miaowang") {
        id = "AiOffice-H5-MiaoWang-Destop";
      } else if (this.curHost == "mifi") {
        id = "AiOffice-H5-MIFI-Destop";
      } else if (this.curHost == "ysd") {
        id = "AiOffice-H5-YiShanDe-Destop";
      } else if (this.curHost == "akpnai") {
        id = "AiOffice-H5-AkpnAI-Destop";
      } else if (this.curHost == "hyundai") {
        id = "AiOffice-H5-HYunDai-Destop";
      } else if (this.curHost == "yff") {
        id = "AiOffice-H5-YunFeiFan-Destop";
      } else if (this.curHost == "aiodiy") {
        id = "AiOffice-AioDiy-Destop";
      } else if (this.curHost == "jiajing") {
        id = "AiOffice-H5-JiaJing-Destop";
      } else if (this.curHost == "xunxiang") {
        id = "AiOffice-H5-XunXiang-Destop";
      } else if (this.curHost == "jiajing") {
        id = "AiOffice-H5-JiaJing-Destop";
      } else if (this.webHost.includes("hbs")) {
        id = "AiOffice-H5-Broadcasting-Destop";
      } else if (this.curHost == "ynz") {
        id = "AiOffice-H5-ERAZER-Destop";
      } else if (this.curHost == "myai") {
        id = "AiOffice-H5-MeiYan-Destop";
      } else if (this.curHost == "zxhy") {
        id = "AiOffice-H5-ZhongXinHongYe-Destop";
      } else {
        id = "AiOffice-Destop-H5";
      }
      return id;
    },

    // 接口数据

    async fetchVersions() {
      const appId = this.getAppId();
      const requests = [
        `${this.ipAddress}/api-product/versionManagement/mainAppId?appId=${appId}&platform=Windows&packageType=DesktopFull`,
        `${this.ipAddress}/api-product/versionManagement/mainAppId?appId=${appId}&platform=Windows&packageType=GreenApp`,
        `${this.ipAddress}/api-product/versionManagement/mainAppId?appId=${appId}&platform=MacOs&packageType=DesktopFull`,
        `${this.ipAddress}/api-product/versionManagement/mainAppId?appId=AiOffice-App&platform=Android&packageType=App`,
        `${this.ipAddress}/api-product/versionManagement/page?appId=AiOffice-History&platform=&packageType=&pageSize=100&pageNumber=1`,
      ];

      try {
        for (const request of requests) {
          const response = await axios.get(request, {
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              "Access-Control-Allow-Origin": "*",
            },
          });

          // 根据请求的顺序将响应数据赋值给相应的变量
          if (
            request.includes("DesktopFull") &&
            request.includes("Windows") &&
            !request.includes("GreenApp")
          ) {
            this.newWindow = response.data?.datas || {};
          } else if (request.includes("GreenApp")) {
            this.newGreen = response.data?.datas || {};
          } else if (request.includes("MacOs")) {
            this.newMac = response.data?.datas || {};
          } else if (request.includes("Android")) {
            this.newAndroid = response.data?.datas || {};
          } else if (request.includes("AiOffice-History")) {
            let historyData = response.data.datas?.list || [];
            if (this.newGreen) {
              this.historyVersions = [this.newGreen, ...historyData];
            } else {
              this.historyVersions = historyData;
            }
          }
        }

    
      } catch (error) {
        console.error("Error fetching versions:", error);
      }
    },

    //鼠标滑过显示二维码
    overAndroidCode() {
      this.androidIcon = false;
      this.androidCode = true;
    },
    outAndroidCode() {
      this.androidIcon = true;
      this.androidCode = false;
    },
    checkHistoryVersion(index) {
      this.versionFlag = index;
    },
    //版本说明介绍
    showVersionDescribe(id) {
      this.describeId = id;
    },
    hideVersionDescribe() {
      this.describeId = "";
    },
    showQrCode() { },

    //发送统计消息到后台
    postStatEvent(version) {
      const val = {
        iting_event_name: "iting_event_download", // 下载
        iting_stat_domain: this.domainName,
        iting_stat_platform: this.userAgent(), //平台  Windows | Mac
        iting_stat_version: version, //版本
        iting_stat_brand: this.curHost, //品牌客户名称
      };
      sendStatEvent("iting_event_download", JSON.stringify(val));
    },

    // 检查操作系统
    userAgent() {
      const userAgent = window.navigator.userAgent.toLowerCase();
      if (
        userAgent.includes("mac") ||
        userAgent.includes("ipad") ||
        userAgent.includes("iphone") ||
        userAgent.includes("ipod")
      ) {
        return "Mac";
      }
      if (userAgent.includes("windows")) {
        return "Windows";
      }
      return "";
    },

    // 云电脑
    getDownloadYunUrl() {
      if (this.curHost == "m70") {
        return {
          url: "https://qjzn-app.oss-cn-hangzhou.aliyuncs.com/yun/YunOffice_6.1.1.2_m70.exe",
          version: "1.8.2.3",
          createTime: "2024-8-21",
        };
      } else if (this.curHost == "zuoh") {
        return {
          url: "https://qjzn-app.oss-cn-hangzhou.aliyuncs.com/yun/YunOffice_6.1.1.2_D2.exe",
          version: "1.8.2.3",
          createTime: "2024-8-21",
        };
      } else if (
        ["sheenhan", "sheenhan.gpt-mouse.com"].includes(this.webHost)
      ) {
        return {
          url: "https://qjzn-app.oss-cn-hangzhou.aliyuncs.com/custom/sheenhan/SheenhanYun_6.1.4.3.exe",
          version: "6.1.4.3",
          createTime: "2024-8-16",
        };
      } else if (this.curHost == "yff") {
        return {
          url: "https://qjzn-app.oss-cn-hangzhou.aliyuncs.com/custom/yunfeifan/YunOffice_6.1.1.2_yff.exe",
          version: "6.1.1.2",
          createTime: "2024-8-27",
        };
      } else if (this.curHost == "tele") {
        return {
          url: "https://qjzn-app.oss-cn-hangzhou.aliyuncs.com/yun/YunOffice_6.2.0.2.exe",
          version: "6.2.0.2",
          createTime: "2024-9-12",
        };
      } else if (this.curHost == "newland") {
        return {
          url: "https://download.iting.chat/aioffice/custom/newland/YunOffice_6.2.0.3-2.exe",
          version: "6.2.0.3",
          createTime: "2024-11-12",
        };
      } else if (this.webHost.includes("hbs")) {
        return {
          url: "https://download.iting.chat/aioffice/custom/henanguangdian/AI%E7%B2%BE%E7%81%B5_6.2.0.0-2.exe",
          version: "6.2.0.0",
          createTime: "2024-10-21",
        };
      } else if (this.curHost == "pansheng") {
        return {
          url: "https://download.iting.chat/aioffice/custom/xindaluPansheng/YunOffice_6.2.0.3.exe",
          version: "6.2.0.3",
          createTime: "2024-10-30",
        };
      } else {
        return {
          url: "https://qjzn-app.oss-cn-hangzhou.aliyuncs.com/yun/YunOffice_6.1.1.0.exe",
          version: "6.1.1.0",
          createTime: "2024-6-28",
        };
      }
    },

    //统信
    getDownloadTXUrl() {
      if (this.curHost == "m70" || this.curHost == "zuoh") {
        return {
          url: "https://qjzn-app.oss-cn-hangzhou.aliyuncs.com/Linux/tongxin/ai.iting.aioffice-uos_1.8.2.3_amd64.deb",
          version: "1.8.2.3",
          createTime: "2024-8-21",
        };
      } else if (
        ["sheenhan", "sheenhan.gpt-mouse.com"].includes(this.webHost)
      ) {
        return {
          url: "https://qjzn-app.oss-cn-hangzhou.aliyuncs.com/custom/sheenhan/ai.iting.sheenhan-uos_1.8.2.1_amd64.deb",
          version: "1.8.2.1",
          createTime: "2024-8-16",
        };
      } else if (this.curHost == "yff") {
        return {
          url: "https://qjzn-app.oss-cn-hangzhou.aliyuncs.com/custom/yunfeifan/ai.iting.yunfeifan-uos_1.8.2.3_amd64.deb",
          version: "1.8.2.3",
          createTime: "2024-8-27",
        };
      } else if (this.curHost == "tele") {
        return {
          url: "https://qjzn-app.oss-cn-hangzhou.aliyuncs.com/Linux/tongxin/ai.iting.aioffice-uos_1.8.2.5_amd64.deb",
          version: "1.8.2.5",
          createTime: "2024-9-12",
        };
      } else if (this.curHost == "newland") {
        return {
          url: "https://download.iting.chat/aioffice/aioffice/ai.iting.aioffice-uos_1.8.2.6_amd64.deb",
          version: "1.8.2.6",
          createTime: "2024-10-12",
        };
      } else if (this.webHost.includes("hbs")) {
        return {
          url: "https://download.iting.chat/aioffice/custom/henanguangdian/ai.iting.broadcasting-uos_1.8.2.6_amd64.deb",
          version: "1.8.2.6",
          createTime: "2024-10-21",
        };
      } else if (this.curHost == "pansheng") {
        return {
          url: "https://download.iting.chat/aioffice/custom/xindaluPansheng/ai.iting.aioffice-uos_1.8.2.7_amd64.deb",
          version: "1.8.2.7",
          createTime: "2024-10-30",
        };
      } else {
        return {
          url: "https://qjzn-app.oss-cn-hangzhou.aliyuncs.com/Linux/AIOffice/ai.iting.aioffice-uos_1.8.0.1_amd64.deb",
          version: "1.8.0.1",
          createTime: "2024-2-1",
        };
      }
    },

    // 麒麟
    getDownloadQLUrl() {
      if (this.curHost == "m70" || this.curHost == "zuoh") {
        return {
          url: "https://qjzn-app.oss-cn-hangzhou.aliyuncs.com/Linux/qilin/ai.iting.aioffice_1.8.2.3_amd64.deb",
          version: "1.8.2.3",
          createTime: "2024-8-21",
        };
      } else if (this.curHost == "yff") {
        return {
          url: "https://qjzn-app.oss-cn-hangzhou.aliyuncs.com/custom/yunfeifan/ai.iting.yunfeifan-kylinos_1.8.2.3_amd64.deb",
          version: "1.8.2.3",
          createTime: "2024-8-27",
        };
      } else if (this.curHost == "tele") {
        return {
          url: " https://qjzn-app.oss-cn-hangzhou.aliyuncs.com/Linux/qilin/ai.iting.aioffice_1.8.2.5_amd64.deb",
          version: "1.8.2.5",
          createTime: "2.24-9-12",
        };
      } else if (this.curHost == "newland") {
        return {
          url: "https://download.iting.chat/aioffice/aioffice/ai.iting.aioffice_1.8.2.6_amd64.deb",
          version: "1.8.2.6",
          createTime: "2024-10-12",
        };
      } else if (this.webHost.includes("hbs")) {
        return {
          url: "https://download.iting.chat/aioffice/custom/henanguangdian/ai.iting.broadcasting-kylinos_1.8.2.6_amd64.deb",
          version: "1.8.2.6",
          createTime: "2024-10-21",
        };
      } else if (this.curHost == "pansheng") {
        return {
          url: "https://download.iting.chat/aioffice/custom/xindaluPansheng/ai.iting.aioffice_1.8.2.7_amd64.deb",
          version: "1.8.2.7",
          createTime: "2024-10-30",
        };
      } else if (this.curHost == "sheenhan") {
        return {
          url: "https://download.iting.chat/aioffice/custom/ShiHan/ai.iting.sheenhan-kylinos_1.8.2.7_amd64.deb",
          version: "1.8.2.7",
          createTime: "2024-11-15",
        };
      }
      else {
        return {
          url: " https://qjzn-app.oss-cn-hangzhou.aliyuncs.com/Linux/AIOffice/ai.iting.aioffice_1.8.0.2_amd64.deb",
          version: "1.8.0.2",
          createTime: "2024-2-1",
        };
      }
    },

    // 麒麟arm
    getDownloadQLArmUrl() {
      if (this.curHost == "yff") {
        return {
          url: "https://download.iting.chat/aioffice/custom/yunfeifan/ai.yunfeifan-kylinos-arm64-yun_2.0.1.0_arm64(1).deb",
          version: "2.0.1.0",
          createTime: "2024-11-4",
        };
      }
    },
  },
};
</script>

<style>
.img-over,
.img-out {
  opacity: 0;
  transition: all 4s;
}

.img-over,
.img-out {
  opacity: 1;
  transition: all 4s;
}
</style>
