const en = {
  lang: "English", //当前语言
  languages: [
    //语言文件
    {
      value: "zh",
      name: "中文",
    },
    {
      value: "en",
      name: "English",
    },
  ],
  //以下为内容区域翻译   //英文
  bannerCommon: {
    title: "AI Office",
    introduce: "MAKE WORK EASIER AND MORE EFFICIENT",
    explain1:
      'AI Office - based on artificial intelligence technology,through the combination of software and hardware products ,Create a new generation  "Simple, efficient and friendly" office scene to improve human work efficiency and happiness',
  },
  bannerOne: {
    title: "AI Voice Mouse - V8",
    introduce: "AI VOICE MOUSE MAKE TYPING SO EASY",
    explain1: "Vocie Typing | 100 + Languages ",
  },
  bannerTwo: {
    title: "AI Presentation Pointer",
    introduce: "REAL-TIME RECORDING  TRANSLATION SUBTITLE",
    explain1: "Audio And Written Minutes | 100 + Global Languages ",
  },
  bannerThree: {
    title: "AI Voice Keyboard",
    introduce: "AI VOICE KEYBOARD MAKE TYPING SO EASY",
    explain1: "Voice Typing | Intelligent Search | AI Translation | Magic Key",
  },
  bannerFour: {
    title: "Recording Translation Conference Headset-A03",
    introduce: "CALL RECORDING  CONFERENCE APP RECORDING",
    explain1: "Call Translation | Recording Text Minutes | One Click Sharing",
  },
  bannerFive: {
    title: "Smart Recording Pen - R3/R5",
    introduce: "RECORD WHILE WRITING  AUDIO AND WRITTEN  MINUTES",
    explain1: "HD Recording | Voice Shorthand | One Click Sharing",
  },
  bannerSix: {
    title: "Intelligent Omnidirectional Microphone",
    introduce: "AI VOICE MOUSE MAKE TYPING SO EASY",
    explain1: "Voice Typing | 100+ Languages ",
  },

  //下载
  window: {
    title: "Windows",
    linkOne: "Download",
    linkTwo: "Beta",
    version: "Version",
    size: "Size",
    updateTime: "UpdateTime",
    compatible: "Compatibility",
    linkExtend: "Windows Download",
    greenLink: "Green Version",
  },
  mac: {
    title: "MacOS",
    linkOne: "Download",
    linkTwo: "App Store",
    version: "Version",
    size: "Size",
    updateTime: "UpdateTime",
    compatible: "Compatibility",
    linkExtend: "Mac Download",
    linkExtend1: "AI x64",
    greenLink: "Green Version",
  },
  android: {
    title: "AI Office Android",
    linkOne: "Download",
    linkTwo: "Google Play",
    version: "Version",
    size: "Size",
    updateTime: "UpdateTime",
    compatible: "Compatibility",
    greenLink: "Green Version",
  },
  patch: {
    title: "Driver Patch",
    linkOne: "64 bit",
    linkTwo: "32 bit",
    version: "Version",
    size: "Size",
    updateTime: "UpdateTime",
    compatible: "Compatibility",
  },
  history: {
    title: "More Versions",
    tip: "Please Choose Client First",
    package: "Installation package",
    product: "Products",
    applicableSystem: "Applicable System",
    historyVersion: "Version",
    time: "Update Time",
    download: "Download",
  },
  contact: {
    link: "Friendly Link",
    oldAddress: "Old WebSite",
    douyin: "Tiktok",
    worktime: "Worktime",
    timeFrame: "Monday To Friday 9:00 ~ 18:00",
    weChat: "WeChat",
    weChatAccount: "WeChat Account",
    customerService: "Customer Wechat",
    video: "Video Account",
    business: "Tmall",
  },
  // 定制
  shadow: {
    stepOne: "First Step: Download the shadowAI client",
    stepTwo: "Second Step: Download the shadow mouse client",
    titleWindow: "ShadowAI SoftWare",
    titleMac: "ShadowAI SoftWare",
    titleShadow: "ZhuoYao Mouse Client",
  },
  // 云电脑
  windowYun: {
    title: "Windows YunDesk",
    linkOne: "Download",
    linkTwo: "",
    version: "Version",
    size: "Size",
    updateTime: "UpdateTime",
    compatible: "Compatibility",
    linkExtend: "Windows Download",
  },
  //统信
  txYun: {
    title: "TongXin UOS",
    linkOne: "Download",
    linkTwo: "",
    version: "Version",
    size: "Size",
    updateTime: "UpdateTime",
    compatible: "Compatibility",
    linkExtend: "Windows Download",
  },
  qlYun: {
    title: "Kirin System",
    linkOne: "Download",
    linkTwo: "",
    version: "Version",
    size: "Size",
    updateTime: "UpdateTime",
    compatible: "Compatibility",
    linkExtend: "Windows Download",
    armTitle: "Kirin System (arm)",
  },
};

export default en;
